import React from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import { DataRoomDocument } from 'silta-ai-backend'
import { SidebarSection } from './Sidebar'
import { themeVariables } from '../themes/themeVariables'
import { Icon } from './Icon'
import { Button } from './Button'
import { downloadDocument } from '../utils/download'

const DocumentLink = styled.div`
    display: block;
    margin-bottom: 30px;
    white-space: normal;
    word-break: break-word;
    overflow-wrap: break-word;
    font-weight: 600;
    color: ${themeVariables.colors.brand};
    &:hover {
        color: ${themeVariables.colors.brand};
    }
    cursor: pointer;
`

const SidebarIcon = styled(Icon)`
    width: 14px;
    height: 14px;
    svg {
        width: 14px;
        height: 14px;
    }
`

type DocumentSidebarContentProps = {
    document: DataRoomDocument
    triggerDeleteDocument: (document: DataRoomDocument) => void
}

export const DocumentSidebarContent = ({
    document,
    triggerDeleteDocument,
}: DocumentSidebarContentProps) => {
    return (
        <>
            <SidebarSection title="File information" emphasized>
                <DocumentLink
                    onClick={() => {
                        downloadDocument(document)
                    }}
                >
                    {document.originalFileName}
                </DocumentLink>
                <div className={cx('d-flex', 'g-10')}>
                    <Button
                        $variant="secondary"
                        className={cx('d-flex', 'g-5', 'align-items-center')}
                        onClick={() => {
                            downloadDocument(document)
                        }}
                    >
                        <SidebarIcon name="download" />
                        Download
                    </Button>
                    <Button
                        $variant="secondary"
                        className={cx('d-flex', 'g-5', 'align-items-center')}
                        onClick={() => {
                            triggerDeleteDocument(document)
                        }}
                    >
                        <SidebarIcon name="trash" />
                        Delete
                    </Button>
                </div>
            </SidebarSection>
            <SidebarSection title="Document title & description">
                <p className="emphasized">{document.title}</p>
                <p className="secondary">{document.description}</p>
            </SidebarSection>
        </>
    )
}
