import React, { useEffect, useState } from 'react'
import { apiClient } from 'utils/clients'
import { AccessDeniedPage } from 'pages/AccessDeniedPage'

function useGlobalAccessDeniedHandler() {
    const [accessDenied, setAccessDenied] = useState(false)

    useEffect(() => {
        const handleAccessDenied = () => setAccessDenied(true)
        apiClient.setAccessDeniedHandler(handleAccessDenied)
        return () => apiClient.setAccessDeniedHandler(undefined)
    }, [])

    return accessDenied
}

interface AccessDeniedHandlerProps {
    children: React.ReactNode
}

export const AccessDeniedHandler: React.FC<AccessDeniedHandlerProps> = ({
    children,
}) => {
    const accessDenied = useGlobalAccessDeniedHandler()

    if (accessDenied) {
        return <AccessDeniedPage />
    }

    return <>{children}</>
}
