import axios, { AxiosInstance, RawAxiosRequestHeaders } from 'axios'
import { FormData } from 'formdata-node'
import {
    Answer,
    AnswerWithRelations,
    AssessmentListItem,
    AssessmentWithRelations,
    CategoryAssignee,
    CategoryAssigneeWithRelations,
    CategoryAssigneesListParams,
    CreateAnswerRequest,
    CreateAssessmentRequest,
    CreateDataRoomRequest,
    CreateModelRequest,
    CreateOutcomeCriterionRequest,
    CreateOutcomeRequest,
    CreatePermissionRequest,
    CreateProjectRequest,
    CreateQuestionRequest,
    CreateReportRequest,
    CreateTeamInviteRequest,
    CreateTeamRequest,
    CreateUserRequest,
    DataRoom,
    DataRoomDocument,
    DataRoomDocumentWithRelations,
    DataRoomWithDocumentCount,
    GenericListParams,
    ListAnswersParams,
    ListAssessmentsParams,
    ListDataRoomDocumentsParams,
    ListDataRoomsParams,
    ListPermissionsParams,
    ListReportsParams,
    ListTeamInvitesParams,
    ModelFilterParams,
    ModelListItem,
    ModelWithRelations,
    Outcome,
    OutcomeCriterion,
    OutcomeCriterionListParams,
    Permission,
    PermissionWithRelations,
    ProjectListItem,
    ProjectWithRelations,
    Question,
    Report,
    ReportListItem,
    ReportTemplate,
    ReportWithRelations,
    Session,
    SignupCode,
    TeamInvite,
    TeamWithRelations,
    UpdateAnswerRequest,
    UpdateAssessmentRequest,
    UpdateDataRoomRequest,
    UpdateModelRequest,
    UpdateOutcomeCriterionRequest,
    UpdateOutcomeRequest,
    UpdatePermissionRequest,
    UpdateProjectRequest,
    UpdateQuestionRequest,
    UpdateReportRequest,
    UpdateReportTemplateRequest,
    UpdateSortOrderRequest,
    UpdateTeamRequest,
    UpdateUserRequest,
    UpdateTeamRoleRequest,
    UpsertCategoryAssigneeRequest,
    User,
    UserWithRelations,
    TeamInviteWithRelations,
    ResourcePointer,
} from 'silta-ai-backend'

export type ApiClientOptions = {
    baseURL?: string
    timeout?: number
    sessionToken?: string
    selectedTeamId?: string
    onSessionExpired?: () => void
}

interface Options {
    signal?: AbortSignal
}

/**
 * WARNING: no type safety here at the moment - need to manually make sure that the API
 * is actually rendering an object of the type expected here!
 */
export class ApiClient {
    private client: AxiosInstance
    private options: ApiClientOptions
    private baseURL: string
    private sessionToken?: string
    private selectedTeamId?: string
    private accessDeniedHandler?: () => void

    constructor(options: ApiClientOptions = {}) {
        const { timeout = 60000, baseURL = 'http://localhost:8938' } = options

        const headers: Partial<RawAxiosRequestHeaders> = {
            'Content-Type': 'application/json',
        }
        this.options = options
        this.baseURL = baseURL
        this.sessionToken = options.sessionToken
        this.selectedTeamId = options.selectedTeamId
        this.client = axios.create({
            baseURL,
            timeout,
            headers,
        })
        this.client.interceptors.request.use((config) => {
            const headers = this.getRequestHeaders()
            Object.entries(headers).forEach(([key, value]) =>
                config.headers.set(key, value)
            )
            return config
        })
        this.client.interceptors.response.use(
            (response) => response, // no-op, only interested in errors
            (error) => {
                if (
                    error.response &&
                    error.response.status === 401 &&
                    this.options.onSessionExpired
                ) {
                    this.options.onSessionExpired()
                }
                if (
                    error.response &&
                    error.response.status === 403 &&
                    this.accessDeniedHandler
                ) {
                    this.accessDeniedHandler()
                }
                return Promise.reject(error)
            }
        )
    }

    getBaseURL(): string {
        return this.baseURL
    }

    getRequestHeaders() {
        const headers: Record<string, string> = {}
        if (this.sessionToken) {
            headers.Authorization = `Bearer ${this.sessionToken}`
        }
        if (this.selectedTeamId) {
            headers['x-silta-team'] = this.selectedTeamId
        }
        return headers
    }

    getSessionToken() {
        return this.sessionToken
    }

    setSessionToken(value: string | undefined): void {
        this.sessionToken = value
    }

    getSelectedTeam(): string | undefined {
        return this.selectedTeamId
    }

    setSelectedTeam(teamId: string | undefined): void {
        this.selectedTeamId = teamId
    }

    public setAccessDeniedHandler(handler: (() => void) | undefined) {
        this.accessDeniedHandler = handler
    }

    /*
     * PROJECTS
     */

    async createProject(
        request: CreateProjectRequest,
        { signal }: Options = {}
    ): Promise<ProjectWithRelations> {
        const response = await this.client.post('/projects', request, {
            signal,
        })
        return response.data
    }

    async getProjects(
        params: GenericListParams = {},
        { signal }: Options = {}
    ): Promise<ProjectListItem[]> {
        const response = await this.client.get('/projects', { params, signal })
        return response.data
    }

    async getProject(
        id: string,
        { signal }: Options = {}
    ): Promise<ProjectWithRelations> {
        const response = await this.client.get(`/projects/${id}`, { signal })
        return response.data
    }

    async updateProject(
        id: string,
        req: UpdateProjectRequest,
        { signal }: Options = {}
    ): Promise<ProjectWithRelations> {
        const response = await this.client.patch(`/projects/${id}`, req, {
            signal,
        })
        return response.data
    }

    async deleteProject(id: string, { signal }: Options = {}): Promise<void> {
        await this.client.delete(`/projects/${id}`, { signal })
    }

    /*
     * MODELS
     */

    async createModel(
        request: CreateModelRequest,
        { signal }: Options = {}
    ): Promise<ModelWithRelations> {
        const response = await this.client.post('/models', request, { signal })
        return response.data
    }

    async getModels(
        params: GenericListParams = {},
        { signal }: Options = {}
    ): Promise<ModelListItem[]> {
        const response = await this.client.get('/models', { params, signal })
        return response.data
    }

    async getModel(
        id: string,
        { signal }: Options = {}
    ): Promise<ModelWithRelations> {
        const response = await this.client.get(`/models/${id}`, { signal })

        return response.data
    }

    async updateModel(
        id: string,
        req: UpdateModelRequest,
        { signal }: Options = {}
    ): Promise<ModelWithRelations> {
        const response = await this.client.patch(`/models/${id}`, req, {
            signal,
        })
        return response.data
    }

    async deleteModel(id: string, { signal }: Options = {}): Promise<void> {
        await this.client.delete(`/models/${id}`, { signal })
    }

    /*
     * OUTCOMES
     */

    async getOutcome(id: string, { signal }: Options = {}): Promise<Outcome> {
        const response = await this.client.get(`/outcomes/${id}`, { signal })
        return response.data
    }

    async getOutcomes(
        params: ModelFilterParams,
        { signal }: Options = {}
    ): Promise<Outcome[]> {
        const response = await this.client.get(`/outcomes`, { params, signal })
        return response.data
    }

    async createOutcome(
        request: CreateOutcomeRequest,
        { signal }: Options = {}
    ): Promise<Outcome> {
        const response = await this.client.post(`/outcomes`, request, {
            signal,
        })
        return response.data
    }

    async updateOutcome(
        id: string,
        request: UpdateOutcomeRequest,
        { signal }: Options = {}
    ): Promise<Outcome> {
        const response = await this.client.patch(`/outcomes/${id}`, request, {
            signal,
        })
        return response.data
    }

    async deleteOutcome(id: string, { signal }: Options = {}): Promise<void> {
        await this.client.delete(`/outcomes/${id}`, { signal })
    }

    async updateOutcomeOrder(
        request: UpdateSortOrderRequest,
        { signal }: Options = {}
    ): Promise<void> {
        await this.client.put(`/outcomes/order`, request, { signal })
    }

    /*
     * QUESTIONS
     */

    async getQuestion(id: string, { signal }: Options = {}): Promise<Question> {
        const response = await this.client.get(`/questions/${id}`, { signal })
        return response.data
    }

    async getQuestions(
        params: ModelFilterParams,
        { signal }: Options = {}
    ): Promise<Outcome[]> {
        const response = await this.client.get(`/questions`, { params, signal })
        return response.data
    }

    async createQuestion(
        request: CreateQuestionRequest,
        { signal }: Options = {}
    ): Promise<Question> {
        const response = await this.client.post(`/questions`, request, {
            signal,
        })
        return response.data
    }

    async updateQuestion(
        id: string,
        request: UpdateQuestionRequest,
        { signal }: Options = {}
    ): Promise<Question> {
        const response = await this.client.patch(`/questions/${id}`, request, {
            signal,
        })
        return response.data
    }

    async deleteQuestion(id: string, { signal }: Options = {}): Promise<void> {
        await this.client.delete(`/questions/${id}`, { signal })
    }

    /*
     * OUTCOME CRITERIA
     */

    async getOutcomeCriterion(
        id: string,
        { signal }: Options = {}
    ): Promise<OutcomeCriterion> {
        const response = await this.client.get(`/outcomeCriteria/${id}`, {
            signal,
        })
        return response.data
    }

    async getOutcomeCriteria(
        params: OutcomeCriterionListParams,
        { signal }: Options = {}
    ): Promise<Outcome[]> {
        const response = await this.client.get(`/outcomeCriteria`, {
            params,
            signal,
        })
        return response.data
    }

    async createOutcomeCriterion(
        request: CreateOutcomeCriterionRequest,
        { signal }: Options = {}
    ): Promise<OutcomeCriterion> {
        const response = await this.client.post(`/outcomeCriteria`, request, {
            signal,
        })
        return response.data
    }

    async updateOutcomeCriterion(
        id: string,
        request: UpdateOutcomeCriterionRequest,
        { signal }: Options = {}
    ): Promise<OutcomeCriterion> {
        const response = await this.client.patch(
            `/outcomeCriteria/${id}`,
            request,
            { signal }
        )
        return response.data
    }

    async deleteOutcomeCriterion(
        id: string,
        { signal }: Options = {}
    ): Promise<void> {
        await this.client.delete(`/outcomeCriteria/${id}`, { signal })
    }

    /*
     * DATA ROOMS
     */

    async createDataRoom(
        request: CreateDataRoomRequest,
        { signal }: Options = {}
    ): Promise<DataRoom> {
        const response = await this.client.post('/dataRooms', request, {
            signal,
        })
        return response.data
    }

    async getDataRooms(
        params: ListDataRoomsParams = {},
        { signal }: Options = {}
    ): Promise<DataRoomWithDocumentCount[]> {
        const response = await this.client.get('/dataRooms', { params, signal })
        return response.data
    }

    async getDataRoom(
        id: string,
        { signal }: Options = {}
    ): Promise<DataRoomWithDocumentCount> {
        const response = await this.client.get(`/dataRooms/${id}`, { signal })
        return response.data
    }

    async updateDataRoom(
        id: string,
        req: UpdateDataRoomRequest,
        { signal }: Options = {}
    ): Promise<DataRoom> {
        const response = await this.client.patch(`/dataRooms/${id}`, req, {
            signal,
        })
        return response.data
    }

    async deleteDataRoom(id: string, { signal }: Options = {}): Promise<void> {
        await this.client.delete(`/dataRooms/${id}`, { signal })
    }

    /*
     * DATA ROOM DOCUMENTS
     * Notes:
     * - Creation via DataRoomDocumentUploader.tsx
     * - Updating not supported at the moment (no updatable fields)
     */

    async getDataRoomDocuments(
        params: ListDataRoomDocumentsParams,
        { signal }: Options = {}
    ): Promise<DataRoomDocumentWithRelations[]> {
        const response = await this.client.get('/dataRoomDocuments', {
            params,
            signal,
        })
        return response.data
    }

    async getDataRoomDocument(
        id: string,
        { signal }: Options = {}
    ): Promise<DataRoomDocument> {
        const response = await this.client.get(`/dataRoomDocuments/${id}`, {
            signal,
        })
        return response.data
    }

    async downloadDataRoomDocument(
        id: string,
        { signal }: Options = {}
    ): Promise<Blob> {
        const response = await this.client.get(
            `/dataRoomDocuments/${id}/download`,
            {
                responseType: 'blob',
                signal,
            }
        )
        return response.data
    }

    async deleteDataRoomDocument(
        id: string,
        { signal }: Options = {}
    ): Promise<void> {
        await this.client.delete(`/dataRoomDocuments/${id}`, { signal })
    }

    /*
     * USER
     */

    async getMyProfile({ signal }: Options = {}): Promise<UserWithRelations> {
        const response = await this.client.get('/users/me', { signal })
        return response.data
    }

    async getUsers(
        params: GenericListParams = {},
        { signal }: Options = {}
    ): Promise<User[]> {
        const response = await this.client.get('/users', { params, signal })
        return response.data
    }

    async createUser(
        request: CreateUserRequest,
        { signal }: Options = {}
    ): Promise<User> {
        const response = await this.client.post('/users', request, { signal })
        return response.data
    }

    async acceptTermsAndConditions({ signal }: Options = {}): Promise<User> {
        const response = await this.client.post(
            '/users/me/acceptTermsAndConditions',
            { signal }
        )
        return response.data
    }

    async uploadAvatar(file: Blob, { signal }: Options = {}): Promise<User> {
        const formData = new FormData()
        formData.append('files', file, 'avatar.png')
        const response = await this.client.post(`/users/me/avatar`, formData, {
            timeout: 300000,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            signal,
        })
        return response.data
    }

    async getAvatar(
        avatarBlobId: string,
        { signal }: Options = {}
    ): Promise<Blob> {
        const response = await this.client.get(
            `/users/avatars/${avatarBlobId}`,
            {
                responseType: 'blob',
                signal,
            }
        )
        return response.data
    }

    async updateUser(
        request: UpdateUserRequest,
        { signal }: Options = {}
    ): Promise<User> {
        const response = await this.client.patch('/users/me', request, {
            signal,
        })
        return response.data
    }

    /*
     * CATEGORY ASSIGNEES
     */

    async getCategoryAssignees(
        params: CategoryAssigneesListParams,
        { signal }: Options = {}
    ): Promise<CategoryAssigneeWithRelations[]> {
        const response = await this.client.get(`/categoryAssignees`, {
            params,
            signal,
        })
        return response.data
    }

    async upsertCategoryAssignee(
        request: UpsertCategoryAssigneeRequest,
        { signal }: Options = {}
    ): Promise<CategoryAssignee> {
        const response = await this.client.post(`/categoryAssignees`, request, {
            signal,
        })
        return response.data
    }

    // multikeyId is a stringified object of CategoryAssignee
    async deleteCategoryAssignee(
        params: {
            assessmentId: string
            category: string
        },
        { signal }: Options = {}
    ): Promise<void> {
        await this.client.delete(`/categoryAssignees`, {
            params: {
                assessmentId: params.assessmentId,
                category: params.category,
            },
            signal,
        })
    }

    /*
     * TEAMS
     */
    async createTeam(
        request: CreateTeamRequest,
        { signal }: Options = {}
    ): Promise<TeamWithRelations> {
        const response = await this.client.post('/teams', request, { signal })
        return response.data
    }

    async getTeams(
        params: GenericListParams = {},
        { signal }: Options = {}
    ): Promise<TeamWithRelations[]> {
        const response = await this.client.get('/teams', { params, signal })
        return response.data
    }

    async getTeam(
        id: string,
        { signal }: Options = {}
    ): Promise<TeamWithRelations> {
        const response = await this.client.get(`/teams/${id}`, { signal })
        return response.data
    }

    async updateTeam(
        id: string,
        req: UpdateTeamRequest,
        { signal }: Options = {}
    ): Promise<TeamWithRelations> {
        const response = await this.client.patch(`/teams/${id}`, req, {
            signal,
        })
        return response.data
    }

    async deleteTeam(id: string, { signal }: Options = {}): Promise<void> {
        await this.client.delete(`/teams/${id}`, { signal })
    }

    async updateUserRoleInTeam(
        teamId: string,
        userId: string,
        req: UpdateTeamRoleRequest,
        { signal }: Options = {}
    ): Promise<void> {
        await this.client.patch(`/teams/${teamId}/users/${userId}`, req, {
            signal,
        })
    }

    async removeUserFromTeam(
        teamId: string,
        userId: string,
        { signal }: Options = {}
    ): Promise<void> {
        await this.client.delete(`/teams/${teamId}/users/${userId}`, { signal })
    }

    /*
     * TEAM INVITES
     */
    async createTeamInvite(
        request: CreateTeamInviteRequest,
        { signal }: Options = {}
    ): Promise<TeamInvite> {
        const response = await this.client.post('/teamInvites', request, {
            signal,
        })
        return response.data
    }

    async getTeamInvites(
        params: ListTeamInvitesParams,
        { signal }: Options = {}
    ): Promise<TeamInviteWithRelations[]> {
        const response = await this.client.get('/teamInvites', {
            params,
            signal,
        })
        return response.data
    }

    async getTeamInvite(
        id: string,
        { signal }: Options = {}
    ): Promise<TeamInvite> {
        const response = await this.client.get(`/teamInvites/${id}`, { signal })
        return response.data
    }

    async deleteTeamInvite(
        id: string,
        { signal }: Options = {}
    ): Promise<void> {
        await this.client.delete(`/teamInvites/${id}`, { signal })
    }

    async acceptTeamInvite(
        id: string,
        { signal }: Options = {}
    ): Promise<void> {
        await this.client.post(`/teamInvites/${id}/accept`, { signal })
    }

    /*
     * SIGNUP CODES
     */

    async getSignupCode(
        id: string,
        { signal }: Options = {}
    ): Promise<SignupCode> {
        const response = await this.client.get(`/signupCodes/${id}`, { signal })
        return response.data
    }

    /*
     * ASSESSMENTS
     */

    async createAssessment(
        request: CreateAssessmentRequest,
        { signal }: Options = {}
    ): Promise<AssessmentWithRelations> {
        const response = await this.client.post('/assessments', request, {
            signal,
        })
        return response.data
    }

    async getAssessments(
        params: ListAssessmentsParams = {},
        { signal }: Options = {}
    ): Promise<AssessmentListItem[]> {
        const response = await this.client.get('/assessments', {
            params,
            signal,
        })
        return response.data
    }

    async getAssessment(
        id: string,
        { signal }: Options = {}
    ): Promise<AssessmentWithRelations> {
        const response = await this.client.get(`/assessments/${id}`, { signal })
        return response.data
    }

    async updateAssessment(
        id: string,
        req: UpdateAssessmentRequest,
        { signal }: Options = {}
    ): Promise<AssessmentWithRelations> {
        const response = await this.client.patch(`/assessments/${id}`, req, {
            signal,
        })
        return response.data
    }

    async startAssessment(
        id: string,
        { signal }: Options = {}
    ): Promise<AssessmentWithRelations> {
        const response = await this.client.post(`/assessments/${id}/start`, {
            signal,
        })
        return response.data
    }

    async deleteAssessment(
        id: string,
        { signal }: Options = {}
    ): Promise<void> {
        await this.client.delete(`/assessments/${id}`, { signal })
    }

    /*
     * ANSWERS
     */

    async startAnswer(
        id: string,
        { signal }: Options = {}
    ): Promise<AssessmentWithRelations> {
        const response = await this.client.post(`/answers/${id}/start`, {
            signal,
        })
        return response.data
    }

    async getAnswer(
        id: string,
        { signal }: Options = {}
    ): Promise<AnswerWithRelations> {
        const response = await this.client.get(`/answers/${id}`, { signal })
        return response.data
    }

    async getAnswers(
        params: ListAnswersParams,
        { signal }: Options = {}
    ): Promise<Answer[]> {
        const response = await this.client.get(`/answers`, { params, signal })
        return response.data
    }

    async updateAnswer(
        id: string,
        request: UpdateAnswerRequest,
        { signal }: Options = {}
    ): Promise<AnswerWithRelations> {
        const response = await this.client.patch(`/answers/${id}`, request, {
            signal,
        })
        return response.data
    }

    async deleteAnswer(id: string, { signal }: Options = {}): Promise<void> {
        await this.client.delete(`/answers/${id}`, { signal })
    }

    async createAnswer(
        request: CreateAnswerRequest,
        { signal }: Options = {}
    ): Promise<AnswerWithRelations> {
        const response = await this.client.post(`/answers`, request, { signal })
        return response.data
    }

    /**
     * Reports
     */

    async getReport(
        id: string,
        { signal }: Options = {}
    ): Promise<ReportWithRelations> {
        const response = await this.client.get(`/reports/${id}`, { signal })
        return response.data
    }

    async getReports(
        params: ListReportsParams = {},
        { signal }: Options = {}
    ): Promise<ReportListItem[]> {
        const response = await this.client.get(`/reports`, { params, signal })
        return response.data
    }

    async startReport(
        id: string,
        { signal }: Options = {}
    ): Promise<ReportWithRelations> {
        const response = await this.client.post(`/reports/${id}/start`, {
            signal,
        })
        return response.data
    }

    async deleteReport(id: string, { signal }: Options = {}): Promise<void> {
        try {
            await this.client.delete(`/reports/${id}`, { signal })
        } catch (error) {
            console.error('Error deleting report:', error)
            throw error
        }
    }

    async createReport(
        request: CreateReportRequest,
        { signal }: Options = {}
    ): Promise<Report> {
        const response = await this.client.post(`/reports`, request, { signal })
        return response.data
    }

    async updateReport(
        id: string,
        request: UpdateReportRequest,
        { signal }: Options = {}
    ): Promise<Report> {
        const response = await this.client.patch(`/reports/${id}`, request, {
            signal,
        })
        return response.data
    }

    async downloadReport(id: string, { signal }: Options = {}): Promise<Blob> {
        const response = await this.client.get(`/reports/${id}/download`, {
            responseType: 'blob',
            signal,
        })
        return response.data
    }

    /**
     * ReportTemplate
     */

    async getReportTemplate(
        id: string,
        { signal }: Options = {}
    ): Promise<ReportTemplate> {
        const response = await this.client.get(`/reportTemplates/${id}`, {
            signal,
        })
        return response.data
    }

    async getReportTemplates(
        params: GenericListParams = {},
        { signal }: Options = {}
    ): Promise<ReportTemplate[]> {
        const response = await this.client.get('/reportTemplates', {
            params,
            signal,
        })
        return response.data
    }

    async uploadReportTemplate(
        file: Blob,
        fileName: string,
        { signal }: Options = {}
    ): Promise<ReportTemplate> {
        const formData = new FormData()
        formData.append('files', file, fileName)
        const response = await this.client.post(`/reportTemplates`, formData, {
            timeout: 300000,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            signal,
        })
        return response.data
    }

    async updateReportTemplate(
        id: string,
        request: UpdateReportTemplateRequest,
        { signal }: Options = {}
    ): Promise<ReportTemplate> {
        const response = await this.client.patch(
            `/reportTemplates/${id}`,
            request,
            { signal }
        )
        return response.data
    }

    async deleteReportTemplate(
        id: string,
        { signal }: Options = {}
    ): Promise<void> {
        await this.client.delete(`/reportTemplates/${id}`, { signal })
    }

    async downloadReportTemplate(
        id: string,
        { signal }: Options = {}
    ): Promise<Blob> {
        const response = await this.client.get(
            `/reportTemplates/${id}/download`,
            {
                responseType: 'blob',
                signal,
            }
        )
        return response.data
    }

    /**
     * Permission
     */

    async getPermissions(
        params: ListPermissionsParams = {},
        { signal }: Options = {}
    ): Promise<PermissionWithRelations[]> {
        const response = await this.client.get('/permissions', {
            params,
            signal,
        })
        return response.data
    }

    async createPermission(
        request: CreatePermissionRequest,
        { signal }: Options = {}
    ): Promise<PermissionWithRelations> {
        const response = await this.client.post(`/permissions`, request, {
            signal,
        })
        return response.data
    }

    async updatePermission(
        id: string,
        request: UpdatePermissionRequest,
        { signal }: Options = {}
    ): Promise<PermissionWithRelations> {
        const response = await this.client.patch(
            `/permissions/${id}`,
            request,
            { signal }
        )
        return response.data
    }

    async deletePermission(
        id: string,
        { signal }: Options = {}
    ): Promise<void> {
        await this.client.delete(`/permissions/${id}`, { signal })
    }

    async getPermissionForResource(
        params: ResourcePointer,
        { signal }: Options = {}
    ): Promise<Permission | null> {
        const response = await this.client.get('/permissions/me', {
            params,
            signal,
        })
        return response.data
    }

    /**
     * Session
     */
    async login(
        email: string,
        password: string,
        { signal }: Options = {}
    ): Promise<Session> {
        const response = await this.client.post(
            '/sessions',
            {
                email,
                password,
            },
            { signal }
        )

        // expiresAt is serialized as a string
        const session: Session = {
            ...response.data,
            expiresAt: new Date(response.data.expiresAt),
        }

        this.sessionToken = session.id

        return session
    }

    async logout({ signal }: Options = {}): Promise<void> {
        await this.client.delete('/sessions', { signal })
        this.sessionToken = undefined
    }
}
